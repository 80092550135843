import styled, { css } from "styled-components";
import Image from "gatsby-image";

import { fadeIn, fadeOut, scaleUp } from "../../../../../../utils/animations";
import { PageChange } from "../../../../../common/Scroll";
import { FixedImageType } from "../../../../../../utils/types";
import { TEXT_BODY_2 } from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: PageChange;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 160px 24px 88px;
  background: #ffffff;
  overflow: hidden;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Title = styled.h2`
  width: 100%;
  margin: 0;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.08;
  color: #151515;
  white-space: pre-line;
  text-transform: uppercase;
  pointer-events: none;
  z-index: 1;
`;

export const ClientWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const ClientTitle = styled.div`
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: #151515;
  border: none;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  margin: 0;
  color: #151515;
  white-space: pre-line;
  font-weight: normal;
  padding-bottom: 16px;

  &:last-of-type {
    padding-bottom: 0px;
  }
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  min-height: 2px;
  margin: 48px 0 32px;
  background: #eaeaea;
  transform: scale(0);
  transform-origin: 0 50%;
  animation: ${scaleUp} 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;

export const Icon = styled(Image)<{
  fixed: FixedImageType;
}>`
  margin: 16px 0;

  img {
    object-fit: contain !important;
  }
`;
